function OurPartner(){
    return <div>
        <div className="row">
            <div className="col"><center>
        <img className="img-fluid" src="https://cdn.pixabay.com/photo/2019/03/17/17/29/business-4061501_960_720.png" alt="vanity"></img><h1 className="col">Partner with us</h1></center>
        </div>
        </div>
        <div className="row container">
            <div className="col col-lg-8 col-md-8" style={{paddingLeft:"100px"}}>
            <p className="para" >
                Amit vanity van deal with the vanity van for shootings, events, and weeding, purpose. if you are a  vanity van owner and want to earn more  you can partner with us and give your vanity van for rental service to us and we will give you the good opportunities to earn more.
                </p>
                <p className="para">
                Such, partner vanity vans will be hosted on our online platform for our guests to rent and travel to various parts of India.
                </p>
            </div>
            <div className="col col-lg-4 col-md-4">
                <img className="img-fluid" src="https://cdn.pixabay.com/photo/2016/10/08/00/33/network-1722861_960_720.jpg" width="400" style={{
    marginBottom: "30px"}} alt="vanity van on hire"></img>
            </div>
        </div>
    </div>
}
export default OurPartner;