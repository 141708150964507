function Aboutus(){
    return <div>
    <div className="row container">
    <div className="col-9">
        <h1>About Amit vanity vans</h1>
    </div>
    </div>
    <div className="row container">
    <div className="col col-lg-9 col-md-9"><p>Amit Vanity Van in Mumbai is one of the leading businesses in the Vanity Van On Hire. Also known for Vanity Van On Hire, Vanity Van Manufacturers, Vanity Van Dealers, Film Make UP Van, Van Dealers and much more.</p><p>Amit vanity van is a private owner operated organisation based in Mumbai, India. Company was established in 2014 to provide Turnkey solution (Rental to fabrication) for Vanity vans.</p> <p>Amit vanity vans has been providing travel enthusiasts a unique way to see and experience exclusive vanity vans. It’s been more than 6 years we’ve been delivering our vanity vans with enthusiasm and trust for shootings, events, and weeding’s.</p>
    
    <h3>Types of vanity vans</h3>
<ul  style={{}}>
<li>Double door vanity</li>
<li>Three door vanity</li>
<li>Celebrity luxury vanity</li>
</ul>    

<p className="para">Double door vanity having facility of double room double Ac double washroom shower TV dressing table vanity mirror mini refrigerator microwave and basic facilities. Cost starting from 5,000 to 10,000 per day including with attendant one driver and cleaner.</p><p>
Three door vanity having facility of three room three Ac three washroom dressing table with vanity mirror each room attached with washroom and basic facilities. Cost starting from 6,000 to 12,000 per day including with attendant one driver and cleaner.</p><p>
Celebrity luxury vanity having a facility of luxury large room with 2 room facility you can convert into one large room also Ac double washroom with luxury facilities shower  Accommodation for 6-person, 2 convertible beds, equipped with full-fledged kitchen, refrigerator, microwave, Hot & cold water, TV music system, Pantry, dining table ,Wi-Fi facilities. Cost starting from 12,000 to 25,000 per day including with attendant one driver and cleaner.
    </p>

    </div>
    <div className="col col-lg-3 col-md-3">
    <div className="text-center">
        <img src="https://github.com/Sandeep-vishwakarma-sfdc/image-repo/blob/master/logo/amit.jpg?raw=true" className="rounded" alt="vanity van" width="400px" height="300px"></img>
    <h6 style={{fontFamily: "Allura, cursive",textAlign: "right",fontWeight:"750",fontSize:"1.8rem"}}>Amit kumar</h6>
    </div>
    </div>
  </div></div>
}
export default Aboutus;